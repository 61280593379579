import { type FC, type PropsWithChildren } from 'react';
import logoVer from 'assets/images/common/logo-ver.png';
import { Helmet } from 'react-helmet';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const About: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <Helmet>
        <title>サービス理念: サイト分析、レポートはGrowth Support</title>
      </Helmet>
      <section
        className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6 text-center"
        id="about"
      >
        <div className="container">
          <header className="c-section__header">
            <h1 className="c-section__title">Growth Support とは</h1>
            <p className="c-section__description">
              Growth Supportは、サイトの成果に貢献することを第一に考え、
              <br className="u-br--mobileNone" />
              アクセス解析データを分析し、改善策のご提案をすることで、サイトの成長をサポートします。
              <br className="u-br--mobileNone" />
              <br className="u-br--mobileNone" />
              また、Webアナリストの視点を学ぶことも目的としており、ご自身で成果を上げられる
              <br className="u-br--mobileNone" />
              分析ができるようサービス開発を行っていますので、ぜひご利用ください。
            </p>
          </header>
        </div>
      </section>
      <section className="c-section--secondary max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6 text-center">
        <div className="container">
          <header className="c-section__header">
            <h2 className="c-section__title">ロゴについて</h2>
            <p className="c-section__description">
              サイトオーナーやWeb担当者さまをサポートするよう4つの思いを込めています。
            </p>
          </header>
          <div className="c-section__body">
            <div className="p-solutions">
              <div className="p-solutions__container">
                <div className="p-solutions__rows">
                  <div className="p-solutions__row">
                    <div className="p-solutions__image">
                      <img alt="Growth Supportロゴ画像" src={logoVer} />
                    </div>
                    <div className="p-solutions__infomation">
                      <h3 className="text-xl dark:text-white">
                        成長し、はばたく鳥
                      </h3>
                      <p className="mb-4 text-gray-900 text-md dark:text-white">
                        本サービスをご利用いただき、サイトとしてもサイト担当者としても成長していくことをイメージしています。
                      </p>
                      <h3 className="text-xl dark:text-white">
                        サイト運営をサポートする優しく頼もしい手
                      </h3>
                      <p className="mb-4 text-gray-900 text-md dark:text-white">
                        その成長をサポートし、しっかりと支えていくという思いを込めています。
                      </p>
                      <h3 className="text-xl dark:text-white">
                        見方を変えて分かりやすく
                      </h3>
                      <p className="mb-4 text-gray-900 text-md dark:text-white">
                        鳥のマークのマルは「クルッと見方を変える」ことをイメージしています。今まで難しく思っていたアクセス解析でも、見方を変えると簡単に分かりやすくなり、成長につなげることができます。
                      </p>
                      <h3 className="text-xl dark:text-white">
                        ご利用者さまに寄り添って
                      </h3>
                      <p className="mb-4 text-gray-900 text-md dark:text-white">
                        「w」は2つのU(ダブルユー)で成り立っています。ご利用者さまにしっかりと寄り添いサポートするという思いを込めています。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default About;
